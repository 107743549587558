<template>
  <v-container class="py-6">
    <div v-if="loading" class="text-center mt-12 mb-12">
      <v-progress-circular size="40" indeterminate color="primary" />
    </div>
    <v-row v-if="!loading">
      <v-col cols="12">
        <div class="box-wrapper">
          <div class="box-overlay" :class="{ open: isSidebar }" @click="isSidebar = !isSidebar"></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <DashbordSidebarTwo />
          </div>

          <v-card class="ml-2 rounded-card" elevation="0">
            <!-- <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn icon @click="isSidebar = !isSidebar">
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </div> -->

            <v-row>
              <v-card-title>
                <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="itemsLink" />
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <h4 class="ml-2">
                      MINHAS FATURAS({{ pagamentos.length }})
                    </h4>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense append-icon="mdi-search" v-model="search" single-line hide-details rounded placeholder="Procurar..." />
                  </v-col>
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </v-row>
                <v-data-table hide-default-footer :headers="headers" :search="search" :items="pagamentos" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :loading="loading">
                  <template v-slot:top> </template>
                  <template v-slot:[`pagamento_vendas_online_items[0].imagem_produto`]="{
                      item,
                    }">
                    <v-list-item-avatar size="80" rounded>
                      <v-img :src="
                          SERVER +
                          item.pagamento_vendas_online_items[0].imagem_produto
                        "></v-img>
                    </v-list-item-avatar>
                  </template>
                  <template v-slot:[`item.statu`]="{ item }">
                    <v-chip v-if="item.statu.id == 5" class="ma-2 text-uppercase font-weight-bold" color="success">
                      <v-icon left> mdi-truck-delivery </v-icon>
                      {{ item.statu.designacao }}
                    </v-chip>

                    <v-chip v-if="item.statu.id == 3" class="ma-2 text-uppercase font-weight-bold" color="error" :title="
                        'Comprovativo de Pagamento da fatura ' +
                        item.id +
                        ' foi rejeitado pelo seguinte motivo: ' +
                        item.motivoRejeicao
                      ">
                      <v-icon left>mdi-close </v-icon>
                      {{ item.statu.designacao }}
                    </v-chip>
                    <v-chip v-if="item.statu.id == 2" class="ma-2 text-uppercase font-weight-bold" color="warning" :title="
                        'O pedido ' +
                        item.codigo +
                        ' encontra-se em análise por um dos nosso Operadores.'
                      ">
                      <v-icon left> mdi-alert-circle-outline </v-icon>
                      {{ item.statu.designacao }}
                    </v-chip>
                    <v-chip v-if="item.statu.id == 1" class="ma-2 text-uppercase font-weight-bold" color="success">
                      <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                      {{ item.statu.designacao }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.pagamento_vendas_online_items`]="{ item }">
                    <span>
                      {{ item.pagamento_vendas_online_items.length }}</span>
                  </template>
                  <template v-slot:[`item.totalPagamento`]="{ item }">
                    <span> {{ formatePrice(item.totalPagamento) }} AKZ</span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <span> {{ formateDate(item.created_at) }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon color="green" btn @click="showDialogFaturaDetalhes(item)">mdi-eye</v-icon>

                    <!-- <v-icon title="Editar Pagamento" hidden v-if="item.statu.id != 1 && item.statu.id != 5" color="blue" btn @click="showDialogEditarPagamento(item)">mdi-pencil</v-icon> -->

                    <!-- <v-icon
                      color="info"
                      btn
                      @click="showDialogProdutosFatura(item)"
                      >mdi-clipboard-outline</v-icon
                    > -->
                    <v-icon btn title="Ver Comprovativo" @click="printComprovativo(item.urlComprovativo)">mdi-file</v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog scrollable v-model="dialogProdutosFatura" max-width="1100" persistent name="dialog-pedido-detalhes">
      <v-card>
        <v-card-title class="secondary white--text">
          FATURA#{{ fatura.codigo }}(
          {{ fatura.pagamento_vendas_online_items.length }}
          produtos )
          <v-spacer />
          <v-btn icon text elevation="1" small @click="dialogProdutosFatura = false">
            <v-icon class="secondary white--text" md>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-card-title>
          <h3 class="red--text">
            TOTAL A PAGAR: {{ formatPrice(fatura.totalPagamento) }} KZ ({{
              fatura.total_extenso
            }})
          </h3>
          <v-spacer />
          <v-btn color="primary" :disabled="loadingDialog" outlined @click="printFatura(fatura)">
            <v-icon>mdi-printer</v-icon> Visualizar Comprovativo
          </v-btn>
        </v-card-title>
        <v-row v-if="loadingDialog">
          <v-col cols="12">
            <v-progress-linear indeterminate color="secondary"></v-progress-linear>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row class="mt-2">
              <!-- <h1>{{ pagamento_vendas_online_items }}</h1> -->
              <v-simple-table name="pedidos">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">CÓDIGO</th>
                      <th class="text-left">PRODUTO</th>
                      <th class="text-left">PREÇO</th>
                      <th class="text-left">QUANTIDADE</th>
                      <th class="text-left">IVA</th>

                      <th class="text-left">SUBTOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in pagamento_vendas_online_items" :key="item.id">
                      <td>#{{ item.id }}</td>
                      <td>
                        {{ item.nomeProduto }}
                      </td>
                      <td>{{ formatePrice(item.precoVendaProduto) }} KZ</td>
                      <td>{{ formatePrice(item.quantidade) }}</td>
                      <td>
                        {{ formatePrice(item.taxaIvaValor) }}({{
                          item.taxaIva
                        }}%)
                      </td>
                      <td>{{ formatePrice(item.subtotal) }} KZ</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogFaturaDetalhes" fullscreen persistent name="dialog-fatura-detalhes">
      <v-card>
        <v-card-title v-if="fatura.pagamento_vendas_online_items.length > 1" class="secondary white--text">
          FATURA #{{ fatura.codigo }}(
          {{ fatura.pagamento_vendas_online_items.length }}
          Produtos )
          <v-spacer />
          <v-btn icon text elevation="1" md @click="closeDialogFaturaDetalhes(fatura)">
            <v-icon class="secondary white--text" md>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title v-else class="secondary white--text">
          FATURA #{{ fatura.codigo }}(
          {{ fatura.pagamento_vendas_online_items.length }}
          Produto )
          <v-spacer />
          <v-btn icon text elevation="1" md @click="closeDialogFaturaDetalhes(fatura)">
            <v-icon class="secondary white--text" md>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-card-title>
          <!-- <h6> {{ fatura }} VALIDADO, A CAMINHHO ,ENTREGUE </h6> -->
        </v-card-title>
        <v-card-title>
          <v-spacer />
          <!--              -->
          <v-btn color="secondary" name="btn-imprimir-fatura" rounded title="Imprimir Fatura" :disabled="loadingDialog" @click="printFatura(fatura)">
            <v-icon>mdi-file</v-icon> Imprimir Fatura
          </v-btn>
        </v-card-title>
        <v-row v-if="loadingDialog">
          <v-col cols="12">
            <v-progress-linear indeterminate color="secondary"></v-progress-linear>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <!-- <h4>{{ fatura }}</h4> -->

            <v-card elevation="2" class="mb-8" id="rounded-card">
              <v-alert type="error" v-if="fatura.statu.id == 3">
                <h6>
                  Comprovativo de Pagamento da fatura
                  {{ fatura.codigo }} foi rejeitado pelo seguinte motivo:
                  <span class="text-uppercase">
                    {{ fatura.motivoRejeicao }}</span>
                </h6>
              </v-alert>
              <v-row v-if="fatura.statu.id == 3">
                <v-col cols="12" md="6">
                  <v-file-input class="ml-4" prepend-icon="mdi-file" label="Comprovativo" v-model="comprovativoBancario" name="comprovativoBancario" outlined dense @change="onComprovativoChange"></v-file-input>
                </v-col>

                <v-col cols="12" md="6">
                  <v-btn @click="sendNovoComprovativo()" class="white--text" rounded color="blue">
                    <!-- <v-icon>mdi-file</v-icon>  -->
                    Enviar Outro Comprovativo
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card elevation="2" id="rounded-card" name="card-rastreamento-envio" v-if="
                fatura.statu.id == 1 ||
                (fatura.statu.id == 5 && fatura.tipoEntregaId == 1)
              ">
              <div class="px-6 py-14">
                <div class="d-flex align-center">
                  <div class="p-relative">
                    <v-avatar class="order-details-custom-done" size="22" color="grey lighten-2">
                      <img v-svg-inline class="w-12 icon orange--text text--darken-1 mr-0" src="@/assets/images/icons/done.svg" alt="" />
                    </v-avatar>
                    <v-avatar size="64" color="secondary" title="Pagamento Confirmado e a encomenda está pronta para a entrega">
                      <img v-svg-inline class="w-32 icon secondary--text text--darken-1 mr-0" src="@/assets/images/icons/package-box.svg" alt="" />
                    </v-avatar>
                  </div>
                  <v-sheet color="secondary" elevation="0" height="4" class="flex-grow-1"></v-sheet>
                  <div>
                    <v-avatar size="64" color="secondary" :title="
                        'Encomenda com a taxa de entrega de ' +
                        formatePrice(fatura.taxaEntrega) +
                        ' AKZ está a caminho do endereço: ' +
                        fatura.enderecoEntrega
                      ">
                      <img v-svg-inline class="w-32 icon white--text mr-0" src="@/assets/images/icons/truck-1.svg" alt="" />
                    </v-avatar>
                  </div>
                  <v-sheet color="grey lighten-2" v-if="fatura.statu.id == 1" elevation="0" height="4" class="flex-grow-1"></v-sheet>
                  <v-sheet color="secondary" v-if="fatura.statu.id == 5" elevation="0" height="4" class="flex-grow-1"></v-sheet>
                  <div>
                    <v-avatar size="64" v-if="fatura.statu.id == 1" color="grey lighten-2" title="Encomenda Entregue com sucesso!">
                      <img v-svg-inline class="w-32 icon white--text mr-0" src="@/assets/images/icons/delivery.svg" alt="" />
                    </v-avatar>
                    <!-- <v-avatar v-if="fatura.statu.id == 5" size="64" color="secondary" title="
                        'Encomenda entregue com sucesso'">
                      <img v-svg-inline class="w-32 icon white--text mr-0" src="@/assets/images/icons/delivery.svg" alt="" />
                    </v-avatar> -->

                    <v-avatar size="64" v-if="fatura.statu.id == 5" color="secondary" title="Encomenda entregue com sucesso">
                      <img v-svg-inline class="w-32 icon secondary--text text--darken-1 mr-0" src="@/assets/images/icons/delivery.svg" alt="" />
                    </v-avatar>
                  </div>
                </div>
                <div class="d-flex justify-end mt-10" v-if="fatura.statu.id == 1">
                  <div class="py-2 px-5 white--text secondary text-center text-wrap rounded-pill">
                    <p class="mb-0">
                      Tempo estimado de entrega:
                      <span class="font-weight-bold" v-if="!isTimeUp">
                        <v-icon color="white">mdi-clock</v-icon>
                        {{ remainingHours }} horas,
                        {{ remainingMinutes }} minutos,
                        {{ remainingSeconds }} segundos
                      </span>
                      <span v-else class="font-weight-bold" color="warning">
                        <v-icon color="white">mdi-clock</v-icon> Tempo previsto
                        de entrega terminado
                      </span>
                    </p>
                  </div>
                </div>
                <v-row class="text-right mt-1" v-if="fatura.statu.id == 1">
                  <v-col cols="12">
                    <v-btn color="secondary" @click="showDialogInfoConfirmarEntrega(fatura)" rounded>
                      <v-icon>mdi-pencil</v-icon> Confirmar Recepção da
                      Encomenda
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>

            <v-card class="mt-6" elevation="2" id="rounded-card">
              <v-card style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12">
                  <h4 class="red--text">
                    TOTAL A PAGAR: {{ formatPrice(fatura.totalPagamento) }} KZ
                    ({{ fatura.total_extenso }} Kwanzas)
                  </h4>
                </v-col>
              </v-card>

              <v-card style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12">
                  <span class="subtitle-1 grey--text">
                    Estado:

                    <v-chip class="text-uppercase font-weight-bold" v-if="fatura.statu.id == 5" color="success">
                      <v-icon left> mdi-truck-delivery </v-icon>
                      {{ fatura.statu.designacao }}
                    </v-chip>

                    <v-chip class="text-uppercase font-weight-bold" v-if="fatura.statu.id == 3" color="error">
                      <v-icon left>mdi-close </v-icon>
                      {{ fatura.statu.designacao }}
                    </v-chip>
                    <span class="red--text text-h6" v-if="fatura.statu.id == 3">
                      {{ fatura.motivoRejeicao }}
                    </span>

                    <v-chip class="text-uppercase font-weight-bold" v-if="fatura.statu.id == 2" color="warning">
                      <v-icon left>mdi-alert-circle-outline </v-icon>
                      {{ fatura.statu.designacao }}
                    </v-chip>
                    <span v-if="fatura.statu.id == 2" class="black--text text-h6">
                      O pedido encontra-se em análise e será validado por um dos
                      nossos operadores.
                    </span>
                    <v-chip class="text-uppercase font-weight-bold" v-if="fatura.statu.id == 1" color="success">
                      <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                      {{ fatura.statu.designacao }}
                    </v-chip>
                  </span>
                </v-col>
              </v-card>
              <v-card style="border: 0.5px solid rgb(178, 183, 179)">
                <v-col cols="12" md="6">
                  <span class="subtitle-1 grey--text"> Data de Pagamento </span>
                  <span class="subtitle-1 black--text">
                    {{ formateDate(fatura.created_at) }}
                  </span>
                </v-col>
              </v-card>

              <!-- <v-card v-if="fatura.tipoEntregaId == 1" style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12" md="6">
                  <span class="subtitle-1 grey--text">
                    Total de Produtos sem taxa de entrega:
                    <span class="subtitle-1 black--text">
                      {{
                        formatePrice(
                          parseFloat(fatura.totalPagamento - fatura.taxaEntrega)
                        )
                      }}
                      AKZ
                    </span>
                  </span>
                </v-col>
              </v-card> -->

              <v-card style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12" md="6">
                  <span class="subtitle-1 grey--text">
                    Total IVA:
                    <span v-if="fatura.totalIva > 0" class="subtitle-1 black--text">
                      {{ formatePrice(fatura.totalIva) }} KZ
                    </span>
                    <span v-else class="text-uppercase black--text font-weight-bold">ISENTO</span>
                  </span>
                </v-col>
              </v-card>
              <v-card v-if="fatura.tipoEntregaId == 1" style="border: 0.5px solid rgb(178, 183, 179)">
                <v-col cols="12" md="12">
                  <span class="subtitle-1 grey--text">
                    Endereço de Entrega:
                    <span class="subtitle-1 black--text">
                      {{ fatura ? fatura.enderecoEntrega : "Indefinido" }}
                    </span>
                  </span>
                </v-col>
              </v-card>

              <v-card v-if="fatura.tipoEntregaId == 1" style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12" md="12">
                  <span class="subtitle-1 grey--text">
                    Ponto de Referência:
                    <span class="subtitle-1 black--text">
                      {{
                        fatura ? fatura.pontoReferenciaEntrega : "Indefinido"
                      }}
                    </span>
                  </span>
                </v-col>
              </v-card>
              <v-card v-if="fatura.tipoEntregaId == 1" style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12" md="6">
                  <span class="subtitle-1 grey--text">
                    Taxa de entrega:
                    <span class="subtitle-1 black--text" v-if="fatura.taxaEntrega>0">
                      {{ formatePrice(fatura.taxaEntrega) }} AKZ
                    </span>
                    <span class=" subtitle-1 blue--text" v-else>
                      GRÁTIS
                    </span>
                  </span>
                </v-col>
              </v-card>

              <v-card style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12">
                  <span class="subtitle-1 grey--text">
                    Tipo de entrega:
                    <v-chip class="text-uppercase font-weight-bold" v-if="fatura.tipoEntregaId == 1" color="success">
                      <v-icon left>mdi-home </v-icon>
                      RECEBER ENCOMENDA EM CASA
                    </v-chip>
                    <v-chip class="text-uppercase font-weight-bold white--text" color="blue" v-if="fatura.tipoEntregaId == 2">
                      <v-icon left>mdi-store </v-icon>
                      LEVANTAR NA LOJA
                    </v-chip>

                    <!-- {{  }} -->
                  </span>
                  <span v-if="fatura.tipoEntregaId == 2 && fatura.statu.id == 1" class="black--text text-h6">| Código de Levantamento da encomenda no Balcão:
                    <span class="font-weight-bold text-h6">#{{ fatura.codigo }}.
                      <!-- <v-btn text style="margin-left: -15px" color="blue"
                        ><v-icon
                          color="blue"
                          :title="
                            'Copiar código: ' +
                            fatura.codigo +
                            ' para área de Transferencia'
                          "
                          >mdi-content-copy</v-icon
                        ></v-btn
                      > -->
                    </span>
                  </span>
                </v-col>
              </v-card>
              <v-card style="border: 1px solid rgb(178, 183, 179)">
                <v-col cols="12" md="6">
                  <span class="subtitle-1 grey--text">
                    Observação na entrega:
                    <span class="subtitle-1 black--text">
                      {{
                        fatura.observacaoEntrega == null
                          ? "Nenhuma Observação"
                          : fatura.observacaoEntrega
                      }}
                    </span>
                  </span>
                </v-col>
              </v-card>
            </v-card>

            <v-card class="mt-6" name="produtos-da-fatura-first" id="rounded-card">
              <v-expansion-panels focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header elevation="0" class="blue--text font-weight-bold">
                    PRODUTOS DA FATURA({{
                      fatura.pagamento_vendas_online_items.length
                    }})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-row class="mt-2">
                        <v-col cols="12">
                          <v-simple-table name="pedidos">
                            <template>
                              <thead>
                                <tr>
                                  <th>IMAGEM</th>
                                  <th class="text-left">CÓDIGO</th>
                                  <th class="text-left">PRODUTO</th>
                                  <th class="text-left">PREÇO</th>
                                  <th class="text-left">QUANTIDADE</th>
                                  <th class="text-left">IVA</th>

                                  <th class="text-left">SUBTOTAL</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in pagamento_vendas_online_items" :key="item.id">
                                  <td>

                                    <router-link class="custom-link" :to="{
                                name: 'ProdutoDetalhes',
                                params: { produto_id: item.uuid },
                              }">
                                      <v-list-item-avatar size="80" rounded>

                                        <v-img :src="SERVER + item.imagem_produto"></v-img>

                                      </v-list-item-avatar>
                                    </router-link>

                                  </td>
                                  <td>#{{ item.uuid }}</td>
                                  <td>
                                    {{ item.nomeProduto }}
                                  </td>
                                  <td>
                                    {{ formatePrice(item.precoVendaProduto) }}
                                    KZ
                                  </td>
                                  <td>{{ formatePrice(item.quantidade) }}</td>
                                  <td>
                                    {{ formatePrice(item.taxaIvaValor) }}({{
                                      item.taxaIva
                                    }}%)
                                  </td>
                                  <td>{{ formatPrice(item.subtotal) }} KZ</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogEditarPagamento" fullscreen persistent name="dialog-editar-Pagamento">
      <v-card>
        <v-card-title class="secondary white--text">
          EDITAR FATURA #{{ fatura.codigo }}
          <v-spacer />
          <v-btn icon text elevation="1" md @click="dialogEditarPagamento = false">
            <v-icon class="secondary white--text" md>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-row v-if="loadingDialog">
          <v-col cols="12">
            <v-progress-linear indeterminate color="secondary"></v-progress-linear>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-card elevation="2" class="mb-8" id="rounded-card">
              <v-stepper v-model="e1">
                <v-stepper-header class="font-weight-medium">
                  <v-stepper-step color="secondary" :complete="e1 > 1" step="1">
                    PRODUTOS DA FACTURA
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step color="secondary" :complete="e1 > 2" step="2">
                    PAGAMENTO
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step color="secondary" step="3">
                    ENTREGA
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1" name="stepper-1">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card class="mt-6" elevation="0" id="rounded-card">
                            <v-card style="border: 1px solid rgb(178, 183, 179)">
                              <v-col cols="12">
                                <h4 class="red--text">
                                  TOTAL A PAGAR:
                                  {{ formatPrice(fatura.totalPagamento) }} KZ
                                  ({{ fatura.total_extenso }} Kwanzas)
                                </h4>
                              </v-col>
                            </v-card>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-card class="mt-6" name="produtos-da-fatura" id="rounded-card">
                        <v-expansion-panels name="editar-pagamento" focusable>
                          <v-expansion-panel>
                            <v-expansion-panel-header elevation="0" class="blue--text font-weight-bold">
                              PRODUTOS DA FATURA ({{
                                fatura.pagamento_vendas_online_items.length
                              }})</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-container>
                                <v-row class="mt-2">
                                  <v-col cols="12">
                                    <v-simple-table name="pedidos">
                                      <template>
                                        <thead>
                                          <tr>
                                            <th>IMAGEM</th>
                                            <th class="text-left">CÓDIGO</th>
                                            <th class="text-left">PRODUTO</th>
                                            <th class="text-left">PREÇO</th>
                                            <th class="text-left">
                                              QUANTIDADE
                                            </th>
                                            <th class="text-left">IVA</th>

                                            <th class="text-left">SUBTOTAL</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="item in pagamento_vendas_online_items" :key="item.id">
                                            <td>
                                              <v-list-item-avatar size="80" rounded>
                                                <v-img :src="
                                                    SERVER + item.imagem_produto
                                                  "></v-img>
                                              </v-list-item-avatar>
                                            </td>
                                            <td>#{{ item.id }}</td>
                                            <td>
                                              {{ item.nomeProduto }}
                                            </td>
                                            <td>
                                              {{
                                                formatePrice(
                                                  item.precoVendaProduto
                                                )
                                              }}
                                              KZ
                                            </td>
                                            <td>
                                              {{
                                                formatePrice(item.quantidade)
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                formatePrice(item.taxaIvaValor)
                                              }}({{ item.taxaIva }}%)
                                            </td>
                                            <td>
                                              {{ formatPrice(item.subtotal) }}
                                              KZ
                                            </td>
                                          </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card>
                    </v-container>

                    <div class="text-right">
                      <v-btn class="ml-2" color="secondary" @click="e1 = 2">
                        AVANÇAR
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2" name="stepper-2">
                    <v-container>
                      <!-- <h4>{{ fatura }}</h4> -->
                      <v-row>
                        <v-col cols="12">
                          <v-radio-group v-model="paymentType" row>
                            <span class="font-weight-bold mr-2">
                              FORMA DE PAGAMENTO:
                            </span>
                            <v-radio color="blue" label="CARTÃO MUTUE" :value="1"></v-radio>
                            <v-radio color="warning" label="SISTEMA BANCÁRIO" :value="2"></v-radio>
                          </v-radio-group>
                          <span v-if="paymentType == 1">
                            Pagamento com Cartão Mutue
                          </span>
                          <span v-else>
                            Pagamento pelo Sistema Bancário Convencional:
                            Transferencia Bancária, Transferencia Express e
                            Depósito Bancário.</span>
                        </v-col>
                      </v-row>
                      <v-container>
                        <v-row class="mt-2" v-if="paymentType == 2">
                          <v-col cols="12">
                            <v-autocomplete prepend-icon="mdi-bank" label="Banco" outlined item-text="sigla" item-value="id" :items="banco" v-model="pagamento.banco_id" />
                          </v-col>
                          <!-- <v-col cols="12" class="ml-6">
                            <p>
                              ENTIDADE BANCÁRIA:
                              <b> {{ pagamento.banco.designacao }}</b>
                            </p>
                            <p>
                              IBAN:
                              <b>
                                {{
                                  pagamento.banco.coordernada_bancaria.iban
                                }}</b
                              >
                            </p>
                            <p>
                              CONTA:
                              <b>
                                {{
                                  pagamento.banco.coordernada_bancaria.num_conta
                                }}</b
                              >
                            </p>

                            <p>
                              TITULAR DA CONTA:
                              <b> {{ pagamento.banco.titular }} </b>
                            </p>
                          </v-col> -->

                          <v-col class="ml-6 font-weight-bold red--text" cols="12">
                            <span class="text-h6">
                              TOTAL A PAGAR:
                              <!-- {{ formatePrice(carrinho.totalPagar) }} KZ ({{
                                carrinho.total_extenso
                              }}) -->
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete prepend-icon="mdi-cash" :label="
                                'Como deseja Pagar ' +
                                'os ' +
                                formatePrice(carrinho.totalPagar) +
                                ' KZ?'
                              " outlined @change="
                                reloadFormaPagamento(
                                  pagamento.forma_pagamento_id
                                )
                              " item-text="descricao" item-value="id" :items="formasDePagamento" v-model="pagamento.forma_pagamento_id" />
                          </v-col>
                        </v-row>

                        <v-row name="Transferencia" v-if="pagamento.forma_pagamento_id">
                          <v-col cols="12" md="6">
                            <v-text-field prepend-icon="mdi-calendar" :rules="[dateValidation]" label="Data de Pagamento" v-model="pagamento.dataPagamentoBanco" outlined type="date"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-file-input prepend-icon="mdi-file" accept=".pdf,.jpg,.jpeg,.png" label="Comprovativo" v-model="comprovativoBancario" name="comprovativoBancario" outlined @change="onComprovativoChange"></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-container>

                    <div class="text-right">
                      <v-btn text outlined @click="e1 = 1" color="primary">
                        VOLTAR
                      </v-btn>
                      <v-btn color="secondary" class="ml-2" @click="e1 = 3">
                        AVANÇAR
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-radio-group v-model="deliveryType" row>
                            <span class="font-weight-bold mr-2">
                              TIPO DE ENTREGA:
                            </span>
                            <v-radio color="blue" label="RECEBER EM CASA" :value="1"></v-radio>
                            <v-radio color="warning" label="LEVANTAR NA LOJA" :value="2"></v-radio>
                          </v-radio-group>
                          <!-- <span v-if="paymentType == 1">
                            Pagamento com Cartão Mutue
                          </span>
                          <span v-else>
                            Pagamento pelo Sistema Bancário Convencional:
                            Transferencia Bancária, Transferencia Express e
                            Depósito Bancário.</span
                          > -->
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="text-right">
                      <v-btn text @click="e1 = 2" outlined color="primary">
                        VOLTAR
                      </v-btn>
                      <v-btn class="ml-2" color="secondary" @click="updatePagamento()">
                        ATUALIZAR
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogAvaliarProduto" max-width="900" persistent name="dialog-fatura-detalhes">
      <v-card>
        <v-card-title class="secondary white--text">
          AVALIAR PRODUTO
          <v-spacer />
          <v-btn icon text elevation="1" md @click="dialogAvaliarProduto = false">
            <v-icon class="secondary white--text" md>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="secondary" />
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <h2 class="font-600 mb-6">O que achaste desse produto?</h2>
            <h5 class="font-600 mb-3">
              Classificação <sup class="primary--text">*</sup>
            </h5>
            <v-rating color="amber" class="mb-3" v-model="produto.classificacao" background-color="amber" dense size="30"></v-rating>
            <h5 class="font-600 mb-3">
              Teu comentário <sup class="primary--text">*</sup>
            </h5>
            <v-textarea color="primary" class="mb-3" v-model="produto.comentario" outlined background-color="white">
            </v-textarea>
            <v-btn color="primary" class="text-uppercase" @click="setAvaliarProduto()">
              Enviar dados</v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-ver-proforma" v-model="dialogVerProforma" width="600" transition="dialog-top-transition">
      <v-card>
        <v-divider />
        <v-card-text>
          <div class="text-center">
            <v-icon color="warning" size="100px">
              mdi-information-outline
            </v-icon>
          </div>
          <v-row>
            <v-col cols="12" class="text-center text-h6">
              Fatura Proforma gerada com sucesso! Veja a aba a tua direita para
              imprimir.
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog name="dialog-logout-sucesso" v-model="dialogComprovativo" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2 text-uppercase">Comprovativo recebido</h6>

          <v-col cols="12">
            <span>Obrigado por nos enviar um outro comnprovativo de pagamento da
              fatura #{{ fatura.id }}. Iremos validar o novo comprovativo o mais
              rápido possivel.</span>
          </v-col>
          <v-btn color="success" @click="reloadPage()"> SAIR</v-btn>
          <!-- <v-progress-linear indeterminate color="secondary"></v-progress-linear> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog--info-comfirmar-entrega" v-model="dialogInfoConfirmarEntrega" max-width="320" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="warning" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2">
            Ao clicar em
            <span class="font-weight-bold"> 'CONFIRMO QUE RECEBI' </span> ,
            temos a certeza que os produtos da fatura
            <span class="font-weight-bold">#{{ codigoFatura }} </span> foram
            entregue no endereço selecionado .
          </h6>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="warning" dense outlined text @click="dialogInfoConfirmarEntrega = false">
            SAIR
          </v-btn>
          <v-btn color="success" class="ml-2" dense @click="setConfirmarEntrega()">
            CONFIRMO QUE RECEBI
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-logout-sucesso" v-model="dialogProdutoEntregueComSucesso" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2 text-uppercase">sucesso</h6>

          <v-col cols="12">
            <span>{{ message }}</span>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined color="green" class="white--text ml-2" @click="requestData()" dense>
            FECHAR POPUP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
  </v-container>
</template>

<script>
import {
    BASE_URL,
    SERVIDOR
} from "@/configs/api";
import axios from "axios";
import moment from "moment";
import DashbordSidebarTwo from "@/components/DashboardSidebarTwo.vue";
export default {
    components: {
        DashbordSidebarTwo,
    },
    data() {
        return {
            message: "",
            dialogProdutoEntregueComSucesso: false,
            codigoFatura: null,
            dialogInfoConfirmarEntrega: false,
            deliveryType: 1,
            paymentType: 1,
            banco: {},
            carrinho: {
                produtos: [],
            },
            comprovativoBancario: null,
            forma_de_pagamento: {
                dataPagamentoBanco: "",
            },
            pagamento: {
                banco: {
                    coordernada_bancaria: {},
                },
            },
            formasDePagamento: [],
            formaDePagamento: 1,
            countdownTimer: null,
            e1: 1,
            dialogEditarPagamento: false,
            startTime: null,
            isTimeUp: false,
            countdown: 24 * 60 * 60, // 24 horas em segundos
            loading: false,
            search: "",
            sortBy: "created_at",
            sortDesc: true,
            alert: {
                text: "",
                type: "",
            },
            headers: [{
                    text: "CÓDIGO",
                    value: "codigo",
                },
                {
                    text: "ITENS",
                    value: "pagamento_vendas_online_items",
                },
                {
                    text: "TOTAL",
                    value: "totalPagamento",
                },
                {
                    text: "ESTADO",
                    value: "statu",
                },
                {
                    text: "DATA",
                    value: "created_at",
                },
                {
                    text: "ACÇÕES",
                    value: "actions",
                    sortable: false,
                },
            ],
            alert: {
                type: "",
            },
            dialogComprovativo: false,
            SERVER: SERVIDOR,
            comprovativoBancario: "",
            loadingDialog: false,
            dialogVerProforma: false,
            produto: {},
            dialogAvaliarProduto: false,
            dialogFaturaDetalhes: false,
            item: {
                pagamento_vendas_online_items: [],
            },
            fatura: {
                statu: {},
                pagamento_vendas_online_items: [],
            },
            pagamento_vendas_online_items: [],
            itemsLink: [{
                    text: "Início",
                    disabled: false,
                    href: "/portal/pages/cliente/dashboard",
                },
                {
                    text: "MEUS PEDIDOS",
                    disabled: true,
                    href: "/",
                },
            ],
            pagamento: {},
            pagamentos: {
                pagamento_vendas_online_items: [],
            },
            dialogProdutosFatura: false,
            totalPedidos: 0,
            query: {},
            pedidos: [{
                    id: 1,
                    faturaReference: "12FGH3",
                    created_at: "06-04-2023",
                    estado_factura: {
                        id: 1,
                        designacao: "ENTREGUE",
                    },
                    valor_a_pagar: "955",
                },
                {
                    id: 2,
                    faturaReference: "21FYH3",
                    created_at: "13-04-2023",
                    estado_factura: {
                        id: 2,
                        designacao: "A CAMINHO",
                    },
                    valor_a_pagar: "55459",
                },
            ],
            overlay: false,
            factura: {},
            factura_detalhes: {},
            pagamentos_facturas: [],
            facturasItems: [],
            search: "",
            loading: false,
            sortBy: "created_at",
            sortDesc: true,
            overlay: false,
            isSidebar: false,
            page: 1,
            itemsPerPage: 5,
            range: [0, 100, 500, 1000],
            itemsTwo: ["Low to High", "High to Low", "Date"],
        };
    },
    computed: {
        remainingHours() {
            return Math.floor(this.countdown / 3600);
        },
        remainingMinutes() {
            return Math.floor((this.countdown % 3600) / 60);
        },
        remainingSeconds() {
            return this.countdown % 60;
        },
    },
    mounted() {
        this.checkUserIsAuth();
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.getClientePagamentos();
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        requestData() {
            this.dialogProdutoEntregueComSucesso = false;
            this.dialogFaturaDetalhes = false;
            this.dialogInfoConfirmarEntrega = false
            this.getClientePagamentos();

        },
        showDialogInfoConfirmarEntrega(fatura) {
            this.codigoFatura = fatura.id;
            this.dialogInfoConfirmarEntrega = true;
        },

        setConfirmarEntrega() {
            let codigo = this.codigoFatura;
            this.loading = true;
            var token = localStorage.getItem("TOKEN");
            const url = `${BASE_URL}/confirmarEntregueVendaOnline`;
            axios
                .post(
                    url, {
                        pagamentoId: codigo,
                    }, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }
                )
                .then((response) => {
                    this.message = response.data.message;
                    console.log(response.data.message);
                    setTimeout(() => {
                        this.loading = false;
                    }, 200);
                    this.dialogProdutoEntregueComSucesso = true;
                })
                .catch((error) => {
                    if (error.response) {
                        this.loading = false;
                        console.log("Erro de resposta do servidor:");
                        console.log("Status:", error.response.status);
                        this.loading = false;
                    }
                });
        },
        closeDialogFaturaDetalhes(item) {
            this.dialogFaturaDetalhes = false;
            if (item.statu.id == 1 || item.statu.id == 5) {
                this.stopCountdown();
            }
        },
        updatePagamento() {
            alert("UPDATING DADOS...");
        },
        stopCountdown() {
            if (this.countdownTimer !== null) {
                clearInterval(this.countdownTimer);
                this.countdownTimer = null;
            }
        },
        showDialogEditarPagamento(item) {
            this.fatura = item;
            this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
            this.dialogEditarPagamento = true;
        },

        checkUserIsAuth() {
            if (localStorage.getItem("TOKEN") == null) {
                this.$router.push("/");
            }
        },
        startCountdown() {
            // this.startTime = this.fatura.updated_at;
            let startTime = new Date();
            let endTime = new Date(this.fatura.estimativaEntrega);

            if (startTime < endTime) {
                const initialCountdown = Math.floor((endTime - startTime) / 1000); // Converte a diferença em segundos
                this.countdown = initialCountdown;
                this.countdownStarted = true;
                this.countdownTimer = setInterval(() => {
                    if (this.countdown <= 0) {
                        this.isTimeUp = true;
                        clearInterval(this.countdownTimer);
                    } else {
                        this.countdown -= 1;
                    }
                }, 1000);
            } else {
                this.isTimeUp = true;
                clearInterval(this.countdownTimer);

                // console.error(
                //   "A data de início é posterior à data de término. Verifique os dados da API."
                // );
            }
        },
        reloadPage() {
            window.location.reload();
        },
        sendNovoComprovativo() {
            var token = localStorage.getItem("TOKEN");
            this.loading = true;
            const url = `${BASE_URL}/reinviarComprativoPagamento`;
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            let formData = new FormData();
            formData.append("pagamentoId", this.fatura.id);
            formData.append("comprovativoBancario", this.comprovativoBancario);
            axios
                .post(url, formData, {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.loading = false;
                    // setTimeout(() => {
                    this.dialogComprovativo = true;
                    // }, 6500);
                })
                .catch((error) => {
                    if (error.response) {
                        this.loading = false;
                        console.log("Erro de resposta do servidor:");
                        console.log("Status:", error.response.status);
                        this.loading = false;
                    }
                });
        },
        onComprovativoChange(e) {
            console.log(e.target.files[0]);
        },
        formateDate(dateTime) {
            return moment(dateTime).format("DD-MM-YYYY HH:mm:ss");
        },
        printComprovativo(comprovativo) {
            // let faturaId=fatura.id
            // this.dialogVerProforma=false
            window.open(comprovativo);
        },
        setAvaliarProduto() {
            let produto = this.produto;
            // alert(JSON.stringify(produto))
            console.log(produto);
        },
        async printFatura(fatura) {
            this.loadingDialog = true;
            let urlJasper = fatura.comprovativoPagamento;
            var token = localStorage.getItem("TOKEN");
            try {
                let response = await axios.get(urlJasper, {
                    responseType: "arraybuffer",
                    headers: {
                        Authorization: `Bearer ${token}`, // Exemplo de cabeçalho de autorização
                    },
                });
                if (response.status === 200) {
                    var file = new Blob([response.data], {
                        type: "application/pdf",
                    });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.loadingDialog = false;
                    this.dialogVerProforma = false;
                }
            } catch (error) {
                this.loadingDialog = false;

                console.log("Erro ao carregar pdf");
                this.dialogVerProforma = false;
            }
        },

                    showDialogFaturaDetalhes(item) {
            this.fatura = item;
            this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
            if (item.statu.id == 1 && item.tipoEntregaId == 1) {
                this.startCountdown();
            }
            this.dialogFaturaDetalhes = true;
        },
        showDialogProdutosFatura(item) {
            this.fatura = item;
            this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
            this.dialogProdutosFatura = true;
        },
        formatePrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getClientePagamentos() {
            this.loading = true;
            var TOKEN_SESSAO = localStorage.getItem("TOKEN");
            const url = `${BASE_URL}/listarTodosPagamentosVendaOnlineUserAutenticado`;
            axios
                .get(url, {
                    headers: {
                        Authorization: "Bearer " + TOKEN_SESSAO,
                    },
                })
                .then((response) => {
                    this.loading = false;

                    this.pagamentos = response.data.data;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response) {
                        // O servidor respondeu com um código de status fora do intervalo 2xx
                        console.log("Erro de resposta do servidor:");
                        console.log("Status:", error.response.status);
                        console.log("Dados de erro:", error.response.data.message);
                        // this.dialogValidateEmail = false;
                        // this.errorTelefoneJaExiste = error.response.data.message;
                        this.alert.type = "error";
                    }
                });
        },

        showPedidoDetalhes(item) {
            this.pagamento = item;
            this.dialogProdutosFatura = true;
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
    },
};
</script>

<style>
#rounded-card {
  border-radius: 25px;
  /* Ajuste o raio para arredondar conforme desejado */
  /* margin: 8px; */
  /* Espaçamento entre os cards */
}

.left-text {
  font-weight: bold;
  /* Texto à esquerda em negrito para destaque */
}

.right-text {
  text-align: right;
  /* Alinha o texto à direita */
}
</style>
